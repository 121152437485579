export const settings = {
  cursor: {
    display: false,
    drag: false,
  },
  generalForm: {
    display: true,
  },
  navigation: {
    backgroundColor: "bg-teal",
    color: "text-gray-darkest",
  },
  mobileMenu: {
    open: false,
  },
  personDetail: {
    display: false,
    member: {},
  },
  footnote: "",
}
