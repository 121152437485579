import "@css/anchor.css"
import "@css/animation.css"
import "@css/article.css"
import "@css/button.css"
import "@css/carousel.css"
import "@css/client.css"
import "@css/cursor.css"
import "@css/display.css"
import "@css/embed.css"
import "@css/extend.css"
import "@css/footer.css"
import "@css/form.css"
import "@css/hci-page.css"
import "@css/image.css"
import "@css/links.css"
import "@css/main.css"
import "@css/member.css"
import "@css/privacy-policy.css"
import "@css/social-good.css"
import "@css/support-comm.css"
import "@css/ttwp-page.css"
import "@css/typography.css"
import "@css/video.css"
import "@css/width.css"
import "@css/work.css"
import "@css/zoom.css"
import "aos/dist/aos.css"

import Provider from "@context/provider"

export const wrapRootElement = Provider
