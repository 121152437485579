/*eslint-disable no-unreachable, no-unused-vars*/
import React, { useReducer, createContext } from "react"
import { state } from "./state"
import { settingsReducer } from "./reducers"

export const settingsContext = createContext()

const { settings: initialSettings } = state

const Provider = (props) => {
  const [settings, dispatchSettings] = useReducer(settingsReducer, initialSettings)

  return <settingsContext.Provider value={[settings, dispatchSettings]}>{props.children}</settingsContext.Provider>
}

const ProviderWrapper = ({ element }) => <Provider>{element}</Provider>

export default ProviderWrapper
